@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* Add this to your CSS file or within a <style> tag in your component */

/* This will style the scrollbar track */
.form-scrollbar::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Light grey track */
    margin-left: 2px;
}

/* This will style the actual scrollbar handle */
.form-scrollbar::-webkit-scrollbar-thumb {
    background-color: #b9bcbe; /* A shade of dark gray-blue for the handle */
    border-radius: 10px; /* Rounded corners on the handle */

}

/* This will style the scrollbar itself (width, etc.) */
.form-scrollbar::-webkit-scrollbar {
    width: 4px; /* Making the scrollbar thin */
    
}

.form-container {
    padding-right: 10px; /* or any value you find suitable */
}